<!--eslint-disable max-len-->
<template>
  <div class="web">
    <v-container>
      <h1 class="display-3 text-center my-16">{{ $t('main.web.title') }}</h1>
      <v-row justify="center">
        <v-col cols="12" xl="8">
          <p class="subtitle-1 mb-5">
            {{ $t('main.web.subtitle') }}
          </p>
          <v-divider class="mb-10"></v-divider>
          <h1 class="display-1 mb-5">WEBSITES</h1>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card
                hover
              >
                <v-img
                  src="../assets/images/web/minerush.png"
                  height="200px"
                  position="center top"
                ></v-img>

                <v-card-title v-html="$t('main.web.websites.minerush.title')"></v-card-title>

                <v-card-subtitle>
                  {{ $t('main.web.websites.minerush.subtitle') }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    href="https://minerush.com"
                    target="_blank"
                    color="purple"
                    text
                  >
                    <v-icon left>mdi-open-in-new</v-icon>
                    {{ $t('main.web.websites.visit') }}
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="mr_show = !mr_show"
                  >
                    <v-icon>{{ mr_show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="mr_show">
                    <v-divider></v-divider>

                    <v-card-text v-html="$t('main.web.websites.minerush.description')"></v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card
                hover
              >
                <v-img
                  src="../assets/images/web/clmayr.png"
                  height="200px"
                ></v-img>

                <v-card-title v-html="$t('main.web.websites.clmayr.title')"></v-card-title>

                <v-card-subtitle>
                  {{ $t('main.web.websites.clmayr.subtitle') }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    href="https://clmayr.at"
                    target="_blank"
                    color="purple"
                    text
                  >
                    <v-icon left>mdi-open-in-new</v-icon>
                    {{ $t('main.web.websites.visit') }}
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <h1 class="display-1 mb-5">WEBAPPS</h1>
          <v-row>
            <v-col cols="12">
              <v-alert
                colored-border
                type="info"
                border="bottom"
                elevation="2"
              >
                {{ $t('info') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <h1 class="display-1 mb-5">GAMES</h1>
          <v-row>
            <v-col cols="12">
              <v-alert
                colored-border
                type="info"
                border="bottom"
                elevation="2"
              >
                {{ $t('info') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Web',
  data() {
    return {
      mr_show: false,
      cl_show: false,
      mc_show: false,
    };
  },
};
</script>
